import NextLink from 'next/link';
import styled from 'styled-components';
import {Link as MuiLink, Typography} from 'components/mui';
import {ComponentType, ElementType, ReactNode} from 'react';
import {Variant} from '@mui/material/styles/createTypography';

const StyledLink = styled(Typography)`
    a:hover {
        background: none;
        text-decoration: none;
    }
`;

type LinkProps = {
    component?: ElementType | (() => ReactNode);
    children: ReactNode;
    color?: string;
    variant?: Variant;
};

export default function Link({
    href,
    variant,
    children,
    color,
    component,
}: LinkProps & {href: string}) {
    return (
        <StyledLink<ComponentType<LinkProps>>
            color={color}
            variant={variant}
            component={component}
        >
            <NextLink href={href} passHref legacyBehavior>
                <MuiLink underline="hover">{children}</MuiLink>
            </NextLink>
        </StyledLink>
    );
}
Link.defaultProps = {
    component: 'span',
    variant: 'body1',
    color: 'inherit',
};

export const ExternalLink = ({
    href,
    variant,
    children,
    color,
    component,
    download,
}: LinkProps & {href: string; download?: string}) => {
    return (
        <StyledLink<ComponentType<LinkProps>>
            color={color}
            variant={variant}
            component={component}
        >
            <MuiLink
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                {...(download && {download})}
            >
                {children}
            </MuiLink>
        </StyledLink>
    );
};

ExternalLink.defaultProps = Link.defaultProps;
