import {MouseEventHandler, ReactNode, useState} from 'react';
import {signOut} from 'next-auth/client';
import styled from 'styled-components';
import {useMutation} from 'graphql/client';
import {Box, Button, Divider, Menu, MenuItem, Typography} from 'components/mui';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LOGOUT from 'graphql/user/mutations/logout.graphql';
import {
    mainHeaderDropdownMenuItems,
    superAdminDropdownMenuItems,
} from 'components/ui/layouts/headers/header.config';
import {logger} from 'utils/logger';
import Link from 'components/ui/links/Link';
import type User from 'core/User';

const DropdownActivationButton = styled(Button)`
    color: white;
    padding-right: 0;
`;

const MenuSection = styled(Box)`
    padding: 5px 20px;
    text-align: left;
    color: ${({theme}) => theme.palette.secondary.main};
`;

const MenuTitle = styled(Typography)`
    font-weight: 700;
    font-size: 1.4rem;
    margin-bottom: 5px;
    color: #2d3640;
`;

const DesktopHeaderDropdown = ({user}: {user: User}) => {
    const [logout] = useMutation<object, object>(LOGOUT, {
        onCompleted() {
            signOut().catch((e) => {
                logger.error(e);
            });
        },
    });

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <DropdownActivationButton
                data-testid="user-menu-button"
                variant="text"
                disableRipple
                onClick={handleClick}
            >
                {user.name}
                <ArrowDropDownIcon />
            </DropdownActivationButton>
            <Menu
                anchorEl={anchorEl}
                data-testid="desktop-header-dropdown"
                keepMounted
                disableScrollLock={true}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuSection>
                    <MenuTitle>Options</MenuTitle>
                    <MenuItems items={mainHeaderDropdownMenuItems} />
                </MenuSection>

                <Divider />

                {user.isSuperuser && user.hasValidSuperAdminEmail && (
                    <Box m="10px 0">
                        <MenuSection>
                            <MenuTitle>Super Admin</MenuTitle>
                            <MenuItems items={superAdminDropdownMenuItems} />
                        </MenuSection>

                        <Divider />
                    </Box>
                )}

                <MenuSection>
                    <DesktopMenuItem
                        id="log-out"
                        disableGutters={true}
                        onClick={() => {
                            logout().catch((e) => logger.error(e));
                        }}
                    >
                        Log Out
                    </DesktopMenuItem>
                </MenuSection>
            </Menu>
        </Box>
    );
};

type MenuItem = {
    id: string;
    label: string;
    href: string;
};

type FeatureFlagMap = {
    [key: string]: boolean;
};
interface MenuItemsProps {
    items: MenuItem[];
    featureFlagMap?: FeatureFlagMap;
}

function MenuItems({items, featureFlagMap = {}}: MenuItemsProps) {
    return (
        <>
            {items.map(({id, label, href}) => {
                if (id in featureFlagMap && featureFlagMap[id] === false) {
                    return null;
                }
                return (
                    <DesktopMenuItemLink key={id} id={id} href={href}>
                        {label}
                    </DesktopMenuItemLink>
                );
            })}
        </>
    );
}

type DesktopMenuItemProps = {
    id: string;
    children: ReactNode;
    disableGutters?: boolean;
    onClick?: MouseEventHandler<HTMLLIElement>;
};

const DesktopMenuItem = ({
    id,
    children,
    onClick,
    disableGutters = false,
}: DesktopMenuItemProps) => {
    return (
        <MenuItem
            disableRipple
            data-testid={id}
            disableGutters={disableGutters}
            onClick={onClick}
        >
            {children}
        </MenuItem>
    );
};

const DesktopMenuItemLink = ({
    id,
    href,
    children,
    disableGutters = false,
}: DesktopMenuItemProps & {href: string}) => {
    return (
        <Link href={href}>
            <DesktopMenuItem {...{id, children, disableGutters}} />
        </Link>
    );
};

export default DesktopHeaderDropdown;
