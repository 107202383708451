import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import {MenuItemWrapper} from 'components/ui/menu/MenuItemWrapper';
import {MenuItemLabel} from 'components/ui/menu/MenuItemLabel';

const LinkWrapper = ({href, children, ...rest}) => {
    return (
        <Link href={href} className="menu-item menu-item--link" {...rest}>
            {children}
        </Link>
    );
};

LinkWrapper.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node,
};

const NoLinkWrapper = ({children, ...rest}) => (
    <div className="menu-item" {...rest}>
        {children}
    </div>
);

NoLinkWrapper.propTypes = {
    children: PropTypes.node,
};

const MenuItem = ({
    children,
    activeNavItem,
    menuItemId,
    href,
    wrapper,
    onClick,
}) => {
    const isActive = activeNavItem === menuItemId;
    const ConditionalWrapper = wrapper;

    return (
        <MenuItemWrapper
            className={`menu-item-wrapper ${isActive ? 'active' : ''}`}
            onClick={onClick}
            data-testid={menuItemId + '-menu-item'}
        >
            <ConditionalWrapper href={href}>
                <MenuItemLabel menuItemId={menuItemId} isActive={isActive}>
                    {children}
                </MenuItemLabel>
            </ConditionalWrapper>
        </MenuItemWrapper>
    );
};

MenuItem.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    menuItemId: PropTypes.string.isRequired,
    activeNavItem: PropTypes.string,
    wrapper: PropTypes.func,
    href: PropTypes.string,
};

MenuItem.defaultProps = {
    isActive: false,
    wrapper: NoLinkWrapper,
    href: '',
};

const NavMenuItem = (props) => <MenuItem wrapper={LinkWrapper} {...props} />;

export {MenuItem, NavMenuItem};
