import PropTypes from 'prop-types';
import styled from 'styled-components';
import {TextField, Autocomplete} from 'components/mui';
import _ from 'lodash';

const StyledAutocomplete = styled(Autocomplete)`
    margin-top: 10px;

    .MuiInput-root {
        margin-top: 10px;
    }

    .MuiButtonBase-root {
        padding: 6px;
    }
`;

const TextFieldSelectDropdown = ({
    id,
    options,
    optionLabelField,
    optionKeyField,
    value,
    onChange,
    label,
    additionalTextFieldProps,
    sortOptions,
    className,
    customPaper,
    hjAllow,
    ...props
}) => {
    if (sortOptions) {
        options = _.sortBy(options, optionLabelField);
    }

    return (
        <StyledAutocomplete
            id={id}
            data-testid={id}
            className={className}
            options={options}
            getOptionLabel={(option) => option[optionLabelField]}
            value={value}
            onChange={onChange}
            PaperComponent={customPaper}
            renderOption={(props, option) => {
                const key = optionKeyField
                    ? option[optionKeyField]
                    : option[optionLabelField];
                return (
                    <li {...props} key={key}>
                        {option[optionLabelField]}
                    </li>
                );
            }}
            renderInput={(params) => {
                const {inputProps, ...restParams} = params;
                return (
                    <TextField
                        {...restParams}
                        label={label}
                        variant="standard"
                        size="small"
                        {...additionalTextFieldProps}
                        inputProps={{...inputProps, 'data-hj-allow': hjAllow}}
                    />
                );
            }}
            size="small"
            {...props}
        />
    );
};

TextFieldSelectDropdown.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    optionLabelField: PropTypes.string.isRequired,
    value: PropTypes.object,
    onChange: PropTypes.func,
    label: PropTypes.string,
    additionalTextFieldProps: PropTypes.object,
    sortOptions: PropTypes.bool,
    className: PropTypes.string,
    customPaper: PropTypes.func,
    optionKeyField: PropTypes.string,
    hjAllow: PropTypes.bool,
};
TextFieldSelectDropdown.defaultProps = {
    value: null,
    additionalTextFieldProps: {},
    sortOptions: false,
    hjAllow: true,
};

export {TextFieldSelectDropdown};
